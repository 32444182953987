.vetted {
    .table-container {
      box-shadow: none; // Remove table container shadow
    }
  
    .table-header-row {
      .table-header {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        border-bottom: none; // Remove line below the header row
      }
    }
  
    .table-row {
      border-bottom: none; // Remove bottom border from rows
    }
  
    .table-cell {
      border: none; // Remove all borders from table cells
     // padding: 8px; // Adjust padding for better alignment
     padding:'0px'
    }
  
    .update-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }
  }