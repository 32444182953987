.container {

  background: #0b52d4;
  .subContainer {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-family: "Poppins";
    .leftSide {
      flex: 1;
      
      position: relative;
      width: 100%;
      height: 500px;
      h2 {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 32px;
        font-weight: 700;
      }

      p {
        margin: 0;
        padding: 0;
        color: #f7f7f7;
        font-size: 21px;
        font-weight: 500;
        line-height: 30px;
      }

      .numberRow {
        display: flex;
        justify-content: space-between;
        margin-top: 100px; /* Default margin for larger screens */
      
        /* For medium devices */
        @media (max-width: 1024px) {
          margin-top: 60px;
        }
      
        /* For small devices */
        @media (max-width: 768px) {
          margin-top: 40px;
        }
      
        /* For extra small devices */
        @media (max-width: 480px) {
          margin-top: 20px;
        }
      }
      

      .numberCircle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 6px solid #062b6e;
        font-size: 24px;
        font-weight: 700;
        color: #062b6e;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      .animateIn {
        opacity: 1;
        transform: translateY(0);
      }

      .iconWrapper {
        position: relative;
        height: 80px;
        margin-bottom: 20px;
      }

      .movingIcon {
        position: absolute;
        top: -50px;
        transition: transform 0.6s ease;
      }
      /* Example of existing animation class */

/* Disable animation on mobile devices */
@media (max-width: 600px) {
  .movingIcon {
    animation: none !important;  /* Disable animation on mobile */
    transform: none !important;  /* Ensure no transformation on mobile */
    position: static !important; /* Keep icon in a static position */
  }
}

    }

    .rightSide {
      // flex: 1.5;
      // position: relative;
      margin-bottom: 0px;
      
      .step {
        position: relative;
       
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.3s ease, transform 0.3s ease;
       

        &.animateIn {
          opacity: 1;
          transform: translateY(0);
        }

        .stepHeader {
          display: flex;
          align-items: center;

          .stepNumber {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #fff;
            color: #062b6e;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            margin-right: 10px;
            border: 2px solid #062b6e;
          }

          h4 {
            margin: 0;
            padding: 0;
            color: #fff;
            font-size: 21px;
            font-weight: 700;
          }
          
          /* Media query for mobile devices */
          @media (max-width: 768px) {
            h4 {
              font-size: 16px; /* Adjust the font-size for mobile */
            }
          }
          
        }

        .stepDesc {
          margin: 0;
          padding: 0;
          margin-left: 50px;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
        @media (max-width: 768px) {
          .stepDesc {
            font-size: 12px;/* Adjust the font-size for mobile */
          }
        }

        .line {
          width: 2px;
          height: calc(100% + 0px);
          background-color: #fff;
          position: absolute;
          left: 20px;
          top: 44px;
          opacity: 0;
          transition: opacity 0.3s ease;

          &.visible {
            opacity: 1;
          }
        }
      }
    }
  }
}
