.forgot-password-main {
  display: flex; /* Use flexbox layout */
  width: 100%; /* Full width of the viewport */
  height: 99vh; /* Full height of the viewport */
  margin: 0;
  padding: 0;
  .childDiv {
    // flex: 1; /* Each child takes up equal space */
    width: 50%; /* Half of the parent width */
    height: 100%; /* Full height of the parent */
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-side-main {
    // background: #e5dfff;
    background: #f3f7ff;
    // filter: drop-shadow(2px 2px 4px rgba(79, 79, 79, 0.12))
    //   drop-shadow(-2px -2px 4px rgba(79, 79, 79, 0.12));
    position: relative;
    box-shadow: inset -3px 3px 4px 0px rgba(150, 150, 150, 0.08);
  }
  .right-side-main {
    width: 50%;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    // padding-left: 80px;
    .right-item {
      .title {
        color: #212121;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .subtitle {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
        max-width: 441px;
      }
      .subtitle2 {
        width: 100%;
        max-width: fit-content;
        & > span:nth-child(1) {
          font-weight: 600;
          line-height: 18px; /* 112.5% */
          margin: 0 4px;
        }
        & > span:nth-child(2) {
          color: #0b52d4;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .password-wrapper {
        width: 100%;
        max-width: 430px;
      }
      .label {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        margin-top: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        display: block;
      }
      .content-input {
        outline: none;
        border-radius: 8px;
        border: 1px solid rgba(17, 17, 19, 0.2);
        // max-width: 395px;
        width: 395px;
        padding: 13px 16px;
        // width: 100%;
        margin-top: 8px;
        background: #fff;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-family: "Poppins";
        & > input {
          border: none;
          outline: none;
          font-family: "Poppins";
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
      .content-input::placeholder {
        color: #a7a7a7;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }

      /* WebKit (Chrome, Safari) */
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      /* Edge */
      input[type="number"] {
        -webkit-appearance: none;
      }

      .criteria {
        color: #34a853;
        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 6px;
        & > span {
          padding: 0 2px;
        }
        & > span:nth-child(1) {
          color: #434343;
          font-family: "Poppins";
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .otpError {
        margin-top: 6px;
        color: #e5261c;
        font-family: "Poppins";
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 18.2px */
      }

      .resendOtp {
        color: #888;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 112.5% */
        margin-top: 34px;
        & > span {
          color: #0b52d4;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .last-button {
        max-width: 430px;
        width: 100%;
        padding: 13px 16px;
        outline: none;
        border: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        border-radius: 8px;
        background: #0B52D4;
        margin-top: 44px;
        cursor: pointer;
      }
    }
  }
}
