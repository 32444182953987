// src/Banner.scss
.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 280px;
  margin-top: 80px;

  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 92%;
    gap: 150px;

    .left-content {
      flex: 1;

      h2 {
        margin: 0;
        color: #fff;
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "Poppins";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1px;
        // text-transform: capitalize;
        max-width: 286px;
      }
    }

    .right-content {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        font-family: "Poppins";
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 180.6%;
        /* 32.508px */
      }

      .get-started-button {
        margin-top: 40px;
        background-color: #ffffff;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        color: #000;
        font-feature-settings: "clig" off, "liga" off;
        // height: 40px;
      }

      .get-started-button:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .animated-text {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.6s ease, transform 0.6s ease;
  }

  .animated-text.visible {
    opacity: 1;
    transform: translateY(0);
  }

}

// Media queries for responsiveness
@media (max-width: 768px) {
  .banner {
    margin-top: 40px;

    .banner-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 30px;
      padding: 30px 16px;

      .left-content {
        margin-bottom: 0px;

        h2 {
          text-align: center;
          font-size: 24px;
          letter-spacing: -1px;
          // text-transform: capitalize;
        }
      }

      .right-content {
        align-items: center;

        p {
          font-size: 16px;
        }

        .get-started-button {
          margin-top: 18px;
          font-size: 16px;
          height: 40px;
        }
      }
    }
  }
}