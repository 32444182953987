.ourservicesnew {
  margin-top: 80px;

  .heading {
    color: var(--systemGrey-900, #212121);
    font-family: "Poppins";
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    text-align: center;
  }

  .cards-row {
    display: flex;
    gap: 70px;
    margin: 0 auto;
    margin-top: 50px;
    width: 92%;
    // border: 1px solid;
    height: 410px;
    justify-content: space-between;

    .flip-card {
      background-color: transparent;
      width: calc(100% / 3);
      //   perspective: 1000px;
      position: relative;

      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer; // Added for better user experience

        &:hover {
          transform: rotateY(180deg);
        }

        .flip-card-front,
        .flip-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
        }

        .flip-card-front {
          background-color: #fff;
          color: black;

          .card-image {
            width: 70%;
            height: auto;
            margin: 0 auto;
            margin-top: 26px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover; // Ensures the image covers the area without stretching
            }
          }

          .card-content {
            color: #333;
            font-family: "Poppins";
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 37px;
            /* 168.182% */
            margin-top: 20px;

            @media (max-width: 1560px) {
              font-size: 16px;
            }
          }
        }

        .flip-card-back {
          width: 90%;
          color: #252525;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          /* 162.5% */
          transform: rotateY(180deg);
          text-align: left;
          margin: 0 auto;
          //   border: 1px solid;
          padding: 0 20px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .cards-phone {
    display: none;
  }
}

@media (max-width: 650px) {
  .ourservicesnew {
    margin-top: 30px;

    .heading {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }

    .cards-row {
      display: none;
    }

    .cards-phone {
      display: block;
      padding: 0 20px;

      .each-card {
        margin-top: 40px;
        fill: #fff;
        box-shadow: 4px 4px 4px rgba(79, 79, 79, 0.08),
          -4px -4px 4px rgba(79, 79, 79, 0.08);
        perspective: 1000px;
        position: relative;
        height: 328px;

        .front,
        .back {
          text-align: center;
          padding-top: 20px;
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          transition: transform 0.6s;
          transform-style: preserve-3d;

          .image {
            width: 50%;
            margin: 0 auto;

            &>img {
              width: 100%;
              height: auto;
            }
          }

          .content {
            margin-top: 20px;
            font-family: "Poppins";
            font-size: 16px;
            color: #252525;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 0 20px;
          }

          .read-more {
            display: flex;
            justify-content: center;
            gap: 6px;
            margin-top: 4px;
            align-items: center;
            color: #0B52D4;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding-bottom: 20px;

            .arrow {
              transform: rotate(180deg);

              &>img {
                height: 20px;
                width: 20px;
              }
            }

            &>div:nth-child(1) {
              margin-top: 4px;
            }
          }
        }

        .back {
          transform: rotateY(180deg);
          text-align: center;
          color: #252525;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400 !important;
          line-height: normal;

          .content {
            font-weight: 400 !important;
            font-size: 14px;
            text-align: left;
          }

          // Style the back as needed
          .read-less {
            display: flex;
            justify-content: center;
            gap: 6px;
            margin-top: 20px;
            align-items: center;
            color: #062b6e;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            padding-bottom: 20px;

            .arrow {
              &>img {
                height: 20px;
                width: 20px;
              }
            }

            &>div:nth-child(1) {
              margin-top: 4px;
            }
          }
        }

        &.flipped .front {
          transform: rotateY(-180deg);
        }

        &.flipped .back {
          transform: rotateY(0deg);
        }

        .arrow.rotated {
          transform: rotate(0deg);
        }
      }

      &>div:nth-child(1) {
        margin-top: 30px;
      }
    }
  }
}