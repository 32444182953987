.client-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  gap: 100px;
  @media (max-width: 1340px) {
    padding: 0 20px;
    width: calc(100% - 40px);
    gap: 60px;
  }
  .left-main {
    width: 100%;
    .title {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .desc {
      color: #34a853;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 32px;
    }
    .form-container {
      margin-top: 24px;
      .contact-form {
        .each-row {
          display: flex;
          gap: 40px;
          margin-top: 32px;
          @media (max-width: 1340px) {
            // justify-content: space-between;
          }
          .each-item,
          .phone-no {
            width: 45%;
            @media (max-width: 1340px) {
              width: 45%;
            }
            & > label {
              font-family: "Poppins";
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 8px;
              display: block;
              & > span {
                color: #fb2929;
              }
            }

            & > input,
            & > textarea,
            .placeholder {
              background: #fff;
              width: calc(100% - 32px);
              padding: 0.5rem;
              //   margin-top: 0.5rem;
              border: none;
              font-family: "Poppins";
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0em;
              padding: 13px 16px;
              border-radius: 8px;
              box-shadow: 2px 2px 4px 0px #2121211a, -3px -2px 4px 0px #2121210f;
              //   margin-top: 8px;
              outline: none;
              opacity: 1 !important;
              .no-value {
                color: rgba(132, 132, 132, 0.6);
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
              }
            }
            .placeholder {
              padding: 8px 16px;
              img {
                margin-top: 4px;
              }
            }
            & > textarea {
              height: 120px;
            }
            & > input::placeholder,
            & > textarea::placeholder {
              color: rgba(132, 132, 132, 0.6);
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 22.4px */
            }
            .errorInput {
              border: 1px solid #e5261c;
              background-color: #e5261c14;
            }
            .missing {
              display: flex;
              gap: 5px;
              align-items: center;
              margin-top: 4px;
              font-size: 14px;
              color: #e5261c;
              font-family: "Poppins";
              & > div:nth-child(1) {
                margin-top: 5px;
              }
            }
          }
          .phone-no {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .dropDown-wrapper {
              width: 80px;

              .mandatory {
                position: block !important;
              }
            }
            .phone-number {
              flex: 1;
              min-width: 50%;
              margin-left: 20px;
            }
          }
          .dropDown-wrapper {
            .missing {
              & > div:nth-child(1) {
                margin-top: 0px;
              }
            }
            .mandatory {
              position: relative !important;
            }
          }
        }
        & > div:nth-child(1) {
          margin-top: 0px;
        }
        & > button {
          margin-top: 40px;
          padding: 13px 40px;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          background: #34a853;
        }
      }
    }
  }

  .right-main {
    max-width: 400px;
    .top-image {
      max-width: 100%;
      & > img {
        width: 100%;
        height: auto;
      }
    }
    .location {
      margin-top: 10px;
      .item {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        .item-image {
          border: 1px solid #e0e0e0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          justify-content: center;
          color: #34a853;
        }
        .title {
          font-family: "Poppins";
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
        }
        .desc {
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          max-width: 432px;
          color: #717171;
        }
      }
    }
  }
  .thank-you {
    box-shadow: 2px 2px 4px 0px #2121211a, -3px -2px 4px 0px #2121210f;
    max-width: 478px;
    padding: 26px 24px;
    height: fit-content;
    text-align: center;
    border-radius: 8px;
    .first {
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 1.28px;
      text-align: center;
      margin-top: 20px;
    }
    .second {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.2px;
      text-align: center;
      margin-top: 16px;
    }
  }
}

@media (max-width: 650px) {
  .client-wrapper {
    margin-top: 30px;
    flex-direction: column;
    padding: 0 16px;
    width: calc(100% - 32px);
    .left-main {
      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .desc {
        margin-top: 14px;
      }
      .desc2Item {
        margin-top: 30px;
      }
      .form-container {
        .contact-form {
          .each-row {
            flex-direction: column;
            gap: 24px;
            margin-top: 24px;
            .each-item,
            .phone-no {
              width: 100%;
              & > label {
                margin-bottom: 10px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
              }

              & > input,
              & > textarea,
              .placeholder {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                .no-value {
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .placeholder {
                padding: 8px 16px;
                img {
                  margin-top: 4px;
                }
              }
              & > textarea {
                height: 120px;
              }
              & > input::placeholder,
              & > textarea::placeholder {
                font-size: 14px;
              }
            }
            .clickedItem {
              // border: 1px solid red;
              z-index: 11;
            }
          }
          & > button {
            margin-top: 30px;
            padding: 9px 24px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.46px;
          }
        }
      }
    }
    .right-main {
      display: none;
    }
    .thank-you {
      padding: 20px 9px;
      .first {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 175% */
        letter-spacing: 1.2px;
      }
      .second {
        margin-top: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }
  }
}
