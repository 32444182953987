// src/HiringProcess.scss
.hiring-container {
  margin: 0 auto;
  width: 92%;
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 30px;
    width: 90%;
  }

  .title {
    color: var(--systemGrey-900, #212121);
    font-family: "Poppins";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    text-align: center;
    margin: 0;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  .step-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 80px;
    margin-top: 50px;
    @media (max-width: 768px) {
      margin-top: 24px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 50px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    // src/StepsCard.scss
    .step-card {
      text-align: center;
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-content {
        margin-bottom: 16px;
      }

      .step-image {
        width: 100px;
        height: 100px;
      }

      .title {
        margin: 0px;
        color: #0b52d4;
        font-family: "Poppins";
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }

      .description {
        margin: 0px;
        color: #4f4f4f;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top: 10px;
        @media (max-width: 480px) {
          font-size: 14px;
          max-width: 183px;
        }
      }
    }

    // Media queries for responsiveness
    @media (max-width: 1200px) {
      .step-cards-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (max-width: 768px) {
      .step-cards-container {
        grid-template-columns: 1fr;
      }
    }
  }
}
