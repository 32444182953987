.carousel-container {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
  }
  
  .carousel-card {
    min-width: calc(100% - 40px); /* Full width minus margin */
    margin: 0 20px; /* Add margins to cards */
    box-sizing: border-box;
  }
  