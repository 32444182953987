.raiseTicketMain {
  position: relative;
  padding: 40px 28px 20px 30px;
  border-radius: 12px;
  background: #FFF;
  width: 714px;
  min-height: 583px;
  font-family: "Poppins";
  box-sizing: border-box;

  .ticket-closeBox {
    position: absolute;
    right: 22px;
    top: 24px;
    width: fit-content;
    height: fit-content;

    img {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .ticket-title {
    width: 136px;
    height: 28px;
    color: #333;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: #333;
    font-family: Poppins;
    font-size: 20px;
  }

  .divider {
    border-bottom: 1px solid #cacaca;
    margin-top: 24px;
  }

  .myClassName {
    width: fit-content;
    min-width: 200px;
    border-radius: 6px;
    border: 1px solid #000;
    margin-top: 32px;
    display: flex;
    align-items: center;

    &>div {
      border: none;
      width: 100%;
      border: 1px solid #000;
      box-sizing: border-box;
      border-radius: 6px;
    }

    &>div:nth-child(1) {
      border: none;
    }
  }

  .ticket-subject {
    width: 656px;
    height: 48px;
    margin: 30px 0;

    &>input {
      width: 656px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #CFCDCD;
      background: #FFF;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding: 12px 16px;
    }
  }

  .ticket-issue {
    height: 130px;
    width: 656px;

    &>textarea {
      width: 656px;
      border-radius: 4px;
      border: 1px solid #cfcdcd;
      background: #fff;
      resize: none;
      min-height: 130px;
      font-size: 16px;
      padding: 12px 16px;
      font-family: Poppins;
      box-sizing: border-box;
    }

    &>textarea::placeholder {
      font-family: Poppins;
    }
  }

  .ticket-info {
    color: #646464;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 16px;
  }

  .attachments {
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    margin: 16px 0px;

    .attachment {
      position: relative;
      width: 42px;
      height: 42px;
      border: 1px solid #062B6E;
      border-radius: 5px;

      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: solid 1px #0B52D4;
      }

      .remove-button {
        position: absolute;
        top: -8px;
        right: -8px;
        background: #0B52D4;
        color: white;
        border: none;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .attachment-container {
      display: flex;
      flex-direction: column;
    }

    .attachment-name {
      margin-top: 7px;
      color: #646464;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 16.8px */
    }
  }

  .upload-section {
    margin-top: 16px;

    .upload-button {
      display: inline-flex;
      align-items: center;
      padding: 12px 12px 14px;
      color: #0B52D4;
      border-radius: 8px;
      border: 1px solid #0B52D4;
      cursor: pointer;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */

      input[type="file"] {
        position: absolute;
        // left: 0;
        // top: 0;
        // right: 0;
        // bottom: 0;
        width: 203px;
        height: 48px;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  .attachment-info {
    color: #7B7777;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 8px;
  }

  .ticket-post {
    &>button {
      border-radius: 8px;
      background: #0B52D4;
      width: 107px;
      height: 48px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      font-family: "Poppins";
      margin-top: 24px;
    }
  }
}