.failureWrapper {
  position: relative;
  padding: 34px;
  max-width: 550px;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .smiley {
    text-align: center;
  }

  .congrats {
    color: rgba(242, 30, 30, 0.93);
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .message {
    color: #4a4a4a;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 14px;
  }

  .buttons {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 36px;
    margin-top: 30px;

    &>button {
      border: none;
      outline: none;
      padding: 12px 16px;
      font-family: "Poppins";
      background: inherit;
      cursor: pointer;
      border-radius: 8px;
      background: #0B52D4;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}