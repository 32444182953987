.privacy-policy {
  max-width: 95%;
  margin: 0 auto;
  position: relative;
  .closeIcon {
    position: absolute;
    top: -15px;
    right: 0;
    cursor: pointer;
  }
}
