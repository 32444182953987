.welcome-page {
  margin-top: 60px;
  margin-left: 5vw;
  .title {
    color: #212121;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    & > span {
      color: #0b52d4;
      font-weight: 600;
    }
  }
  .desc {
    color: #9e9e9e;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 14px;
  }
  .step-wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .stepBox {
      width: fit-content;
      border-radius: 8px;
      border: 1px solid #4318ff;
      background: #f2efff;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      gap: 15px;
      .title {
        color: #0b52d4;
        font-family: "Poppins";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .desc {
        color: #212121;
        font-family: "Poppins";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 10px;
      }
    }
  }
  .start {
    margin-top: 50px;
    & > button {
      border: none;
      outline: none;
      height: fit-content;
      width: fit-content;
      a {
        text-decoration: none;
        border-radius: 8px;
        background: #062b6e;
        border: none;
        outline: none;
        padding: 13px 24px;
        color: #fff;
        font-family: "Poppins";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        cursor: pointer;
      }
    }
  }
}
