.holdonWrapper {
  //   margin-top: 100px;
  padding: 24px 26px;
  .first {
    text-align: center;
    & > img {
      height: 100px;
    }
  }
  .title {
    color: #212121;
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0px;
  }
  .desc {
    color: #4a4a4a;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 168.75% */
    max-width: 360px;
    margin-top: 8px;
    & > span {
      color: #4a4a4a;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .okay {
    text-align: center;
    margin-top: 16px;
    & > button {
      border-radius: 8px;
      background: #062b6e;
      padding: 12px 22px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
