.profile-card {
    box-sizing: border-box;
    width: 440px;
    height: 304px;
    border-radius: 16px;
    box-shadow: -2px -2px 4px 0px rgba(33, 33, 33, 0.12),
      2px 2px 4px 0px rgba(33, 33, 33, 0.12);
    padding: 30px 15px;
    cursor: pointer;
    .card-header {
      display: flex;
      gap: 12px;
      height: 24px;
  
      .availability,
      .job-type {
        height: 24px;
        box-sizing: border-box;
        border-radius: 12px;
        background: #fff;
        padding: 2px 11px;
        display: flex;
        align-items: center;
  
        & > span:nth-child(1) {
          color: #062b6e;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.2px;
        }
        & > span:nth-child(2) {
          color: #062b6e;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.2px;
        }
      }
  
      .availability {
        width: 195px;
      }
      .job-type {
        width: 202px;
      }
    }
  
    .card-body {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      & > img {
        width: 140px;
        height: 140px;
        flex-shrink: 0;
        border-radius: 140px;
        background: lightgray 50% / cover no-repeat;
        object-fit: cover;
      }
      .candidate-info {
        color: #fff;
        letter-spacing: 0.2px;
        .role {
          font-size: 13px;
          font-weight: 500;
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        .location,
        .experience,
        .domain {
          display: flex;
          align-items: flex-start;
          gap: 12px;
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  
    .card-footer {
      display: flex;
      gap: 16px;
      margin-top: 20px;
      & > span {
        height: 34px;
        box-sizing: border-box;
        padding: 7px 8px 6px 8px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.9);
        color: #062b6e;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.2px;
      }
    }
  }


  .skeleton {
    background-color: #eee;
    animation: shimmer 1.5s infinite ease-in-out;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -0px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  