.Professional-details {
  margin-left: 5vw;
  font-family: "Poppins";
  .title {
    color: #7c7c7c;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 30px;
  }
  .skills-container {
    .skills-heading {
      display: flex;
      gap: 30px;
      // margin-top: 24px;
      .title-values {
        width: 260px;
        & > div:nth-child(1) {
          color: #212121;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
        & > div:nth-child(2) {
          margin-top: 8px;
        }
      }
    }
    .skill-item {
      .each-skill {
        margin-top: 32px;
        .add-language {
          margin-top: 32px;
          color: #0B52D4;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          display: flex;
          align-items: center;
          gap: 12px;
          width: fit-content;
          margin-bottom: 10px;
          cursor: pointer;
          & > span {
            font-size: 24px;
          }
        }
        & > div:nth-child(1) {
          color: #212121;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
        & > div:nth-child(2) {
          color: #afafaf;
          font-family: "Poppins";
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .language-experience {
          margin-bottom: 12px;
          margin-top: 30px;
          .language-and-years {
            display: flex;
            gap: 30px;
            & > div {
              max-width: 260px;
            }
            .selected {
              color: #34a853;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 22.4px */
            }
            .errorField {
              display: flex;
              gap: 6px;
              max-width: 270px;
              width: 100%;
              align-items: center;
              font-family: "Poppins";
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              height: 40px;
              padding: 0px 6px;
              // padding-bottom: 4px;
              background-color: #e5261c14;
              border: 1px solid #e5261c;
              border-radius: 4px;
              & > div:nth-child(2) {
                margin-bottom: 2px;
              }
            }
          }
          .framework-experience {
            margin-left: 55px;
            .framework-and-years {
              display: flex;
              gap: 30px;
              margin-top: 12px;
              align-items: center;
              & > div {
                max-width: 260px;
              }
            }
          }
        }
        & > div:nth-child(3) {
          margin-top: 0px;
        }
      }
    }
  }
  .dropDown-wrapper {
    width: 100%;
    position: relative;
    z-index: 10;
    .placeholder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 16px;
      border-radius: 8px;
      box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
      color: rgba(167, 167, 167, 0.67);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      border: 1px solid #e0e0e0;
      background: #f8f9fb;
      .value {
        color: #4f4f4f;
        font-weight: 500;
      }
    }
    .options-wrapper {
      box-shadow: 1px 4px 5px 5px rgb(0 0 0 / 4%),
        -2px -2px 4px 0px rgba(79, 79, 79, 0.04);
      position: absolute;
      width: 100%;
      background: #f8f9fb;
      color: #4f4f4f;
      font-weight: 500;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      line-height: 140%; /* 22.4px */
      height: fit-content;
      max-height: 200px;
      overflow-y: auto;
      margin-top: 2px;
      z-index: 100;
      box-shadow: 2px 2px 4px 0px rgba(61, 60, 60, 0.278);
      & > div {
        padding: 13px 16px;
        border-bottom: 1px solid #dfdcdc;
        cursor: pointer;
      }
      & > div:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    max-width: 1240px;
    justify-content: space-between;
    margin: 50px 0px;
    margin-right: 50px;
    margin-bottom: 100px;
    & > button {
      border: none;
      outline: none;
      cursor: pointer;
      padding: 13px 24px;
      font-family: "Poppins";
      background: inherit;
      border-radius: 8px;
      font-size: 16px;
      line-height: 140%; /* 22.4px */
      font-style: normal;
    }
    & > button:nth-child(1) {
      border: 1px solid #a7a7a7;
      color: #a7a7a7;
      font-weight: 500;
    }
    & > button:nth-child(2) {
      background: #0B52D4;
      color: #fff;
      font-weight: 600;
    }
  }
  .check-item {
    width: 18px;
    height: 18px;
  }
  .skills-items-content {
    border-radius: 8px;
    border: 1px solid rgba(224, 224, 224, 0.4);
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.04),
      -2px -2px 4px 0px rgba(79, 79, 79, 0.04);
    padding: 26px 40px;
    margin-top: 30px;
    max-width: 1160px;
  }
}
