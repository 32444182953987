.back-to-home {
  width: fit-content;
  display: flex;
  cursor: pointer;
  font-family: "Poppins";
  // align-items: center;
  color: var(--black-high, #212121);
  // text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // border: 1px solid;
  gap: 10px;

  & > div:nth-child(2) {
    margin-top: 4px;
  }
}
