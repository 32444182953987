.slick-cloned {
  visibility: hidden; // Hide cloned slides
}

.slick-active + .slick-cloned {
  visibility: visible; // Show cloned slides only when active
}

.container {
  background-size: cover;
  background-position: center;
 
  text-align: center;
  color: #fff;
}

.title {
  margin: 0px;
  padding: 0px;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  letter-spacing: 0.2px;
}

.slider {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 45px;

  .slick-list {
    padding: 0;
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 10px; /* Padding on both sides for a total of 20px gap */
    box-sizing: border-box;
  }

  .slick-track {
    display: flex !important;
    align-items: stretch !important; /* Ensure all cards stretch to the same height */
  }

  .slick-arrow {
    display: none !important;
  }
}

.card {
 
  opacity: 0;
  transform: translateY(100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content is spaced out */
  width: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.24);
  padding: 20px;
  text-align: left;
  color: #333;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.animateIn {
  opacity: 1;
  transform: translateY(0);
}

.quoteText {
  // margin: 8px 8px 0px 0px;
  padding: 0;
  color: #fff;

  font-family: "Cabin";
  font-size: 44px;
  font-style: normal;
  font-weight: 700 !important;
  // line-height: 31px;
}
.quoteTextSecond {
  // margin: 0px 0px 0px 0px;
  padding: 0;

  color: #fff;
  font-family: "Cabin";
  font-size: 44px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 31px;
}

.cardTitle {
  margin: 0px 0 0 0;
  padding: 0;
  height: 31px;
  color: #062b6e;
  text-align: center;
  font-family: "Poppins";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 31px;
  
  text-align: left;
}

.cardDesc {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
 
  margin: 8px 0 0 0 !important;
}

.authorSection {
  display: flex;
  align-items: center;
  margin-top: auto; /* Align the author section to the bottom */
}

.authorImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.authorName {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
}

.authorTitle {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
}