.footer-main {
  width: 100%;
  // margin-top: 80px;
  background: #fff;
  box-shadow: -3px -3px 4px 0px rgba(79, 79, 79, 0.1);
  font-family: "Poppins";

  .footerContent {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    @media (max-width: 1340px) {
      width: calc(100% - 40px);
    }

    .first {
      display: flex;
      align-items: center;

      .logo-wdp {
        // width: 83px;
        // height: 120px;
        cursor: pointer;

        &>img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .second {
      .each-item {
        display: flex;
        gap: 16px;
        margin-top: 32px;
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;

        .location-icon {
          color: #0b52d4;
        }
      }

      &>div:nth-child(1) {
        margin-top: 0px;
      }

      .main-address {
        font-weight: 600;
      }
    }

    .third {
      .contact {
        .details-item {
          a {
            text-decoration: none;
            color: inherit;
            display: flex;
            gap: 16px;
            color: #212121;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin-top: 24px;

            .email-icon {
              color: #0b52d4;
              margin: 0px !important;
            }

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        &>div:nth-child(1) {
          margin-top: 0px;
        }
      }

      .social {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 32px;

        svg,
        img {
          width: 40px;
          height: 40px;
          color: #0B52D4;
          transition: transform 0.3s ease;
          /* Remove translateY */
        }

        .mui-icon {
          display: inline-block;
          transition: transform 0.3s ease-in-out;
        }

        /* Shake animation */
        .mui-icon:hover {
          animation: shake 1s ease-in-out;
        }

        @keyframes shake {
          0% {
            transform: translateX(-3px);
          }

          25% {
            transform: translateX(3px);
          }

          50% {
            transform: translateX(-3px);
          }

          75% {
            transform: translateX(3px);
          }

          100% {
            transform: translateX(0);
          }
        }





        /* Remove unnecessary margin on the right */
        a {
          margin-right: 0;
          /* Ensure no margin between icons and other elements */
        }
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    height: 94px;
    background: rgba(204, 222, 255, 0.6);
    color: #212121;
    font-size: 16px;
    font-family: "Poppins";
    line-height: 150%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1340px) {
      padding-bottom: 10px;
    }
  }
}

@media (max-width: 650px) {
  .footer-main {
    margin-top: 40px;

    .footerContent {
      flex-direction: column;
      padding: 16px 0;
      width: calc(100% - 32px);

      .first {
        text-align: center;

        &>div {
          margin: 0 auto;
        }
      }

      .second {
        .each-item {
          gap: 12px;
          margin-top: 16px;
          font-size: 14px;
          line-height: 150%;
        }

        &>div:nth-child(1) {
          margin-top: 26px;
        }

        .main-address {
          font-weight: 600;
        }
      }

      .third {
        .contact {
          margin-top: 18px;
          display: flex;
          gap: 20px;

          .details-item {
            a {
              gap: 12px;
              font-size: 14px;
              line-height: 150%;
              margin-top: 0px;
            }
          }
        }

        .social {
          justify-content: center;
          margin-top: 16px;

          a {
            margin-right: 0 !important;
            /* Ensure no margin between social icons and the next element */
          }
        }
      }
    }

    .copyright {
      height: fit-content;
      padding: 16px 0;
      font-size: 14px;
      line-height: normal;
    }
  }
}