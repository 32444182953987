.no-offers-main {
  margin-top: 50px;
  text-align: center;
  .desc {
    color: #8d8d8d;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }
}
