.issue-conversation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f9f9f9;
  .header-main {
    display: flex;
    justify-content: space-between;
    padding: 40px 60px;
    .header-date {
      color: #4f4f4f;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      & > span {
        color: #062b6e;
      }
    }
    .header-button {
      display: flex;
      .header-title {
        color: #212121;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .ticket-main {
    margin-left: 60px;

    .ticket-info {
      width: 674px;
      height: 133px;
      fill: #fff;
      background-color: #fff;
      border-radius: 30px;
      border-bottom-right-radius: 0;
      padding: 16px 24px;
      box-sizing: border-box;
      filter: drop-shadow(2px 2px 4px rgba(33, 33, 33, 0.12))
        drop-shadow(-2px -2px 4px rgba(33, 33, 33, 0.12));
    }

    .ticket-subject {
      margin-bottom: 14px;
      // width: 105px;
      height: 24px;
      color: #212121;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .ticket-description {
      width: 620px;
      height: 63px;
      color: #7b7777;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ticket-timestamp {
      width: 674px;
      height: 18px;
      margin-top: 10px;
      color: #9b9b9b;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: flex-end;
    }
  }
  .messages {
    margin-left: 60px;
    .message-container {
      display: flex;

      .message {
        display: flex;
        flex-direction: column;
        padding: 16px 24px;
        border-radius: 10px;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 674px;
        height: 95px;
        background-color: #e8f0fe;
        box-sizing: border-box;
        border-radius: 30px;
        border-bottom-left-radius: 0;

        color: #7b7777;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .avator {
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
        margin-left: 2px;

        & > img {
          width: 100%;
        }
      }
    }
    .timestamp {
      margin-top: 10px;
      color: #9b9b9b;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

// .inputContainer {
//     display: flex;
//     align-items: center;
//     padding: 10px;
//     border-top: 1px solid #ddd;
//     background-color: #fff;

//     .input {
//         flex-grow: 1;
//         padding: 10px;
//         border: none;
//         border-radius: 20px;
//         background-color: #f0f0f0;
//         margin-right: 10px;

//         &:focus {
//             outline: none;
//         }
//     }

//     .sendButton {
//         background-color: #0056b3;
//         color: #fff;
//         border: none;
//         border-radius: 50%;
//         padding: 10px;
//         cursor: pointer;

//         &:focus {
//             outline: none;
//         }
//     }

//     .attachmentIcon {
//         margin-right: 10px;
//         cursor: pointer;
//         color: #0056b3;
//     }
// }
