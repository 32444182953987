.contact-main {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  @media (max-width: 1340px) {
    padding: 0 20px;
    width: calc(100% - 40px);
  }
  .left-main {
    max-width: 438px;
    width: 100%;
    .title {
      font-family: "Poppins";
      font-size: 26px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 1.2px;
      text-align: left;
      text-transform: capitalize;
    }
    .desc {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.2px;
      text-align: left;
      margin-top: 12px;
    }
    .form-container {
      .contact-form {
        & > label {
          display: block;
          margin-top: 24px;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          & > span {
            color: #fb2929;
          }
        }

        & > input,
        & > textarea {
          width: 100%;
          padding: 0.5rem;
          margin-top: 0.5rem;
          border: none;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          padding: 13px 16px;
          border-radius: 8px;
          box-shadow: 2px 2px 4px 0px #2121211a, -3px -2px 4px 0px #2121210f;
          margin-top: 8px;
          outline: none;
        }
        & > textarea {
          height: 120px;
        }
        & > input::placeholder,
        & > textarea::placeholder {
          color: rgba(132, 132, 132, 0.6);
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        & > button {
          margin-top: 1rem;
          padding: 13px 24px;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          background: #062b6e;
        }
      }
    }
  }
  .right-main {
    .top-image {
      max-width: 400px;
      & > img {
        width: 100%;
        height: auto;
      }
    }
    .location {
      margin-top: 10px;
      .item {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        .item-image {
          border: 1px solid #e0e0e0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          justify-content: center;
          color: #0b52d4;
        }
        .title {
          font-family: "Poppins";
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
        }
        .desc {
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          max-width: 432px;
          color: #717171;
        }
      }
    }
  }
  .thank-you {
    box-shadow: 2px 2px 4px 0px #2121211a, -3px -2px 4px 0px #2121210f;
    max-width: 478px;
    padding: 26px 24px;
    height: fit-content;
    text-align: center;
    border-radius: 8px;
    .first {
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 1.28px;
      text-align: center;
      margin-top: 20px;
    }
    .second {
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.2px;
      text-align: center;
      margin-top: 16px;
    }
  }
}

@media (max-width: 650px) {
  .contact-main {
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    padding: 0 16px;
    width: calc(100% - 32px);
    .left-main {
      max-width: 100%;
      .title {
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .desc {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .form-container {
        margin-top: 6px;
        width: 100%;
        max-width: calc(100% - 32px);

        .contact-form {
          & > label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }

          & > input,
          & > textarea {
            margin-top: 10px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }
          & > textarea {
            height: 80px;
          }
          & > input::placeholder,
          & > textarea::placeholder {
            font-size: 14px;
          }
          & > button {
            margin-top: 30px;
            padding: 9px 24px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.46px;
          }
        }
      }
    }
    .right-main {
      display: none;
      .top-image {
        max-width: 100%;
      }
      .location {
        margin-top: 10px;
        .item {
          margin-top: 20px;
          align-items: center;
          .item-image {
            height: 36px;
            width: 36px;
          }
        }
      }
    }
    .thank-you {
      padding: 20px 9px;
      .first {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 175% */
        letter-spacing: 1.2px;
      }
      .second {
        margin-top: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }
  }
}
