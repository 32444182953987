.candidate-offers-header {
  margin-left: 5vw;
  margin-top: 40px;
  .title1 {
    margin-top: 30px;
    color: #212121;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .desc1 {
    margin-top: 12px;
    color: #8d8d8d;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .offers-link {
    display: flex;
  }
  .offers-title {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    & > a {
      text-decoration: none;
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .active {
      color: #0b52d4;
      font-weight: 500;
      border-bottom: 2px solid #0b52d4;
      border-radius: 1px;
    }
  }
}
