.we-offer-3 {
  width: 100%;
  // background: #f4f1ff;
  background: rgba(204, 222, 255, 0.3);
  @media (max-width: 1340px) {
    padding: 0 20px;
    width: calc(100% - 40px);
  }
  .wrapper {
    width: 100%;
    // max-width: 1200px;
    width: fit-content;
    margin: 0 auto;
    margin-top: 60px;
    padding: 60px 0;
    @media (max-width: 1220px) {
      // padding: 0 20px;
    }
    .heading {
      font-family: "Poppins";
      font-size: 30px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0.2px;
      text-align: center;
    }
    .item {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 40px;
      margin-top: 30px;
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
        overflow-x: scroll;
        padding-left: 20px;
        padding-right: 20px;
      }
      .each-item {
        background: #fff;
        padding: 30px;
        border-radius: 8px;
        // text-align: center;
        // flex: 0 0 calc(33.33% - 10px); /* Adjust the width and margin as per your requirement */
        margin: 5px;
        .item-img {
          width: 68px;
          height: 56px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        & > div:nth-child(2) {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 31px;
          /* identical to box height, or 148% */
          text-align: center;
          color: #505d68;
          margin-top: 17px;
          max-width: 409px;
        }
        & > div:nth-child(3) {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          // max-width: 340px;
          /* or 150% */

          text-align: center;

          color: #a1aeb7;
          margin-top: 8px;
        }
        .view-arrow,
        .view-arrow-main {
          display: none;
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: center;
      button {
        padding: 15px 40px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.46px;
        cursor: pointer;
        margin-top: 50px;
        // background: #000;
        background: #eb3223;
        color: #fff;
      }
    }
  }
}

@media (max-width: 650px) {
  .we-offer-3 {
    padding: 0 16px;
    width: calc(100% - 32px);
    .wrapper {
      margin-top: 0px;
      padding: 0px;
      padding: 20px 0;
      width: 100%;
      .heading {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
      }
      .item {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 16px;
        padding: 0px;
        width: 100%;
        margin-top: 20px;
        .each-item {
          padding: 30px 18px;
          position: relative;
          & > div:nth-child(2) {
            color: #505d68;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 100%;
            text-align: center;
            margin: 12px auto;
          }
          .title-and-arrow {
            // border: 1px solid;
            display: flex;
            position: relative;
            justify-content: center;
            .item-title {
              width: 85%;
            }
            .view-arrow-main {
              display: block;
              position: absolute;
              right: 0;
              transform: rotate(180deg);
            }
          }
          .item-desc,
          & > div:nth-child(3) {
            display: none;
            margin-top: 16px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0 16px;
            max-width: 85%;
          }
          .view-arrow {
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            top: 0px;
            right: 18px;
          }
        }
      }
    }
  }
}
