.instructionsContainer {
  margin-left: 5vw;
  max-width: 860px;
  margin-bottom: 100px;
  font-family: "Poppins";

  .eachInstruction {
    margin-top: 30px;
    .instructionTitle {
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      background: #e9e9e9;
      padding: 11px 20px;
      color: #212121;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      margin-bottom: 20px;
      cursor: pointer;
    }
    .instructionDesc {
      .instructionSubSection {
        .instructionSubtitle {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 25.2px */
          //   margin-top: 20px;
          margin-bottom: 20px;
        }
        .instructionItem {
          max-width: 850px;
          display: flex;
          color: #212121;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 20px;
          gap: 10px;
          // text-align: justify;
          & > div {
            & > span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
