.round4StartOrComplete {
  margin-left: 5vw;
  font-family: "Poppins";
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  padding: 20px 30px;
  max-width: 800px;
  margin-top: 50px;
  box-sizing: unset !important;
  .status-main {
    display: flex;
    justify-content: space-between;
    .text-1 {
      border-radius: 3px;
      background: #ccdeff;
      padding: 7px 16px;
      width: fit-content;
      font-weight: 600;
    }
    .text-2 {
      color: #4f4f4f;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 16px;
    }
    .text-3 {
      color: #222;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 152%; /* 21.28px */
      margin-top: 14px;
    }
  }
  .completed {
    color: rgb(45, 151, 45);
    margin-top: 12px;
    font-weight: 600;
    font-size: 1rem;
  }
  .assessmentUI {
    .uploadUi {
      .main-item {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .buttons {
          display: flex;
          align-items: center;
          gap: 24px;
          .start-test {
            & > button {
              margin-top: 0px;
              border-radius: 8px;
              border: 1px solid #0B52D4;
              color: #0B52D4;
              background: inherit;
            }
          }
          .view {
            color: #0B52D4;
            font-family: "Poppins";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 25.2px */
            text-decoration-line: underline;
            cursor: pointer;
          }
        }
        .time {
          height: 28px;
          width: 150px;
          display: flex;
          padding: 11px;
          gap: 16px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.12),
            -2px -2px 4px 0px rgba(79, 79, 79, 0.09);
          color: #0b52d4;
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 28px */
        }
      }
      .files {
        color: #999;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        margin-top: 10px;
      }
      .uploaded-file {
        display: flex;
        align-items: center;
        padding: 11px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.1),
          -2px -2px 4px 0px rgba(33, 33, 33, 0.05);
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 16px;
        width: fit-content;
        margin-top: 12px;
        .cross {
          cursor: pointer;
          margin-top: 5px;
        }
      }
      .submit-round {
        border-radius: 8px;
        background: #0bb762;
        color: #fff;
        font-family: "Poppins";
        margin-top: 24px;
        padding: 13px 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        border: none;
        outline: none;
      }
    }
    .start-test {
      & > button {
        border-radius: 8px;
        outline: none;
        cursor: pointer;
        padding: 13px 24px;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        margin-top: 30px;
        background: #0B52D4;
        color: #fff;
        border: none;
      }
    }
  }
}

.preStartInstructions {
  position: relative;
  padding: 30px;
  max-width: 850px;
  box-sizing: unset !important;
  .close {
    position: absolute;
    top: 20px;
    right: 25px;
    & > img {
      cursor: pointer;
    }
  }
  .text-1 {
    color: #000;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
  }
  .text-2 {
    color: #4f4f4f;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    margin-top: 24px;
  }
  .text-3 {
    color: #7b7777;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
    & > div {
      display: flex;
      gap: 6px;
    }
  }
  .start-test {
    & > button {
      border-radius: 8px;
      background: #0B52D4;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 13px 24px;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      margin-top: 30px;
    }
  }
}
