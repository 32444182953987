/* Initial state before animation */
.fade-up {
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  /* Animation when the element is in view */
  .results-section.animate .fade-up {
    opacity: 1;
    transform: translateY(0);
  }
  