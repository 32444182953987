.details-header {
  margin-top: 60px;
  margin-left: 5vw;
  display: flex;
  gap: 75px;

  & > a {
    text-decoration: none;
    display: flex;
    align-items: center;
    align-items: center;
    color: #9e9e9e;
    font-family: "Poppins";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 2px solid f8f9fb;
    gap: 4px;
    padding: 0 4px;
    padding-bottom: 4px;
    cursor: pointer;
    & > img {
      width: 24px;
      height: 24px;
    }
  }
  .active {
    color: #0b52d4;
    font-weight: 500;
    border-bottom: 2px solid #0b52d4;
  }
}
