.container {
  background-size: cover;
  background-position: center;
 
  text-align: center;
  color: #fff;
  font-family: "Poppins";
}

.title {
  margin: 0px;
  padding: 0px;
  color: #212121;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: 0.2px;
}

.offersGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 items per row */
  gap: 40px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.offerBox {
  background-color: rgba(255, 255, 255, 0.9);

  border-radius: 8px;
  // transition: transform 0.6s ease, opacity 0.6s ease;
  // opacity: 0;
  // transform: translateY(100px); /* Initial hidden state */
  text-align: center;
  box-shadow: -2px -2px 4px rgba(33, 33, 33, 0.08),
    2px 2px 4px rgba(33, 33, 33, 0.08);
  // display: flex;
  // flex-direction: column;
  // // justify-content: space-between;
  // // align-items: center;
 

}

.icon {
  display: flex; /* Use Flexbox */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center if needed */
  flex-shrink: 0; /* Prevent the icon from shrinking */
  height: 60px;
}


.icon img {
  width: 60px;
  height: 60px;
  display: block;
}

.offerTitle {
  color: #062b6e;
  text-align: center;
  font-family: "Poppins";
  
  font-style: normal;
  font-weight: 700 !important;
  margin: 14px 0 0 0 !important;
  flex-grow: 1; /* Ensures title takes up space */
 height: 48px;
}

.offerDesc {
  color: #4f4f4f;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
  
}

.animateIn {
  opacity: 1;
  transform: translateY(0); /* Moves the row into view */
}
