@keyframes windFlow {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
  
  .wind-flow {
    background: linear-gradient(90deg, rgba(240, 240, 240, 0.2) 25%, rgba(240, 240, 240, 1) 50%, rgba(240, 240, 240, 0.2) 75%);
    background-size: 400% 100%;
    animation: windFlow 1.5s ease-in-out infinite;
  }
  