.main {
    .features-container {
        position: relative;
        overflow: hidden; // Prevents overflow during animations
        padding: 60px 0;
        background: rgba(204, 222, 255, 0.3);
        margin-top: 0px;

        @media (max-width: 768px) {
            // margin-top: 40px;
            padding: 30px 0;
        }

        .title {
            color: var(--systemGrey-900, #212121);
            text-align: center;
            font-family: "Poppins";
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.2px;
            text-transform: capitalize;
            margin-bottom: 50px;

            @media (max-width: 768px) {
                margin-bottom: 30px;
                font-size: 16px;
            }
        }

        .data-container {
            position: relative;
            height: auto; // Adjusts based on content
            display: flex;
            flex-direction: column; // Stacks feature cards vertically
            gap: 50px; // Space between cards
        }
    }


    .feature-card {
        position: relative;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 1; // Hidden by default
        // transform: translateY(100%); // Start from below the viewport
        // transition: transform 0.5s, opacity 0.5s;

        .text-content {
            flex: 1;

            .feature-title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .feature-description {
                font-size: 16px;
                line-height: 1.5;
            }
        }

        .image-content img {
            max-width: 200px;
            height: auto;
        }
    }
}

// src/Features.scss
.main {
    .features-container {
        padding: 60px 0;
        background: rgba(204, 222, 255, 0.3);
        // margin-top: 80px;

        @media (max-width: 768px) {
            margin-top: 0px;
            padding: 30px 0;
        }

        .title {
            color: var(--systemGrey-900, #212121);
            text-align: center;
            font-family: "Poppins";
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.2px;
            text-transform: capitalize;
            margin-bottom: 50px;

            @media (max-width: 768px) {
                margin-bottom: 30px;
                font-size: 16px;
            }
        }

        .data-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin: 0 auto;
            width: 92%;
            column-gap: 56px;
            row-gap: 40px;

            @media (max-width: 768px) {
                max-width: 90%;
                grid-template-columns: 1fr;
                gap: 20px;
                padding: 16px 13px;
                padding-top: 0px;
            }

            @media (max-width: 480px) {
                gap: 28px;
                padding: 10px;
                padding-top: 0px;
            }
        }
    }


    .feature-card {
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        text-align: left;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 224px;
        border-left: 4px solid #0b52d4;
        position: relative;
        border-bottom-left-radius: 0px;

        @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
            padding: 15px;
        }

        @media (max-width: 480px) {
            padding: 10px;
        }

        .text-content {
            flex: 1;

            .feature-title {
                color: #212121;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 31px;
                /* 172.222% */

                @media (max-width: 768px) {
                    font-size: 1.2em;
                }

                @media (max-width: 480px) {
                    font-size: 1em;
                }
            }

            .feature-description {
                color: #959595;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
                margin-top: 10px;

                @media (max-width: 768px) {
                    font-size: 1em;
                }

                @media (max-width: 480px) {
                    font-size: 0.9em;
                }
            }
        }

        .image-content {
            margin-left: 20px;

            @media (max-width: 768px) {
                margin-left: 0;
                margin-top: 15px;
            }

            .feature-image {
                max-width: 150px;
                max-height: 190px;

                @media (max-width: 768px) {
                    max-width: 140px;
                }

                @media (max-width: 480px) {
                    max-width: 140px;
                }
            }
        }
    }

    .feature-card::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: -4px;
        width: 50%;
        /* Half width */
        height: 4px;
        /* Border thickness */
        background-color: #0b52d4;
        /* Border color */

        border-bottom-right-radius: 4px;
        /* Matches the border radius */

        border-bottom-left-radius: 4px;
        /* Matches the border radius */
    }
}