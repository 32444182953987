.issue-tracker {
    padding: 0 60px;

    .title {
        margin-top: 60px;
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}

.issue-tracker__tabs {
    display: flex;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.issue-tracker__tabs button {
    color: #9C9C9C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.issue-tracker__tabs button.active {
    color: #0B52D4;
    border-bottom: 2px solid #0B52D4;
}

.issue-list-container {
    margin-bottom: 30px;
}

.issue-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.issue-list__date {
    color: #333;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.issue-item {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 720px;
    height: 135px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.issue-item__content {
    flex-grow: 1;
}

.issue-item__content div {
    color: #212121;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

.issue-item__content p {
    width: 620px;
    height: 63px;
    color: #7B7777;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.issue-item__action {
    display: flex;
    align-items: center;
}