.login-wrapper {

  // height: fit-content;
  // min-height: 100vh;
  // width: fit-content;
  // min-width: 94%;
  // padding: 50px;
  .login-main {
    display: flex;
    // width: calc(100% - 300px);
    width: 100%;
    margin: 0 auto;
    // height: calc(100% - 110px);
    height: fit-content;
    min-height: 100vh;
    border-radius: 15px;
    // border: 1px solid;
    // box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.66);

    font-family: "Poppins";

    // filter: drop-shadow(2px 2px 4px rgba(79, 79, 79, 0.12))
    //   drop-shadow(-2px -2px 4px rgba(79, 79, 79, 0.12));
    &>div {
      width: 50%;
      //   text-align: right;
      display: flex;
      justify-content: center;
      // flex-direction: column;
    }

    &>div:nth-child(2) {
      // text-align: left;
    }

    .left-image {
      // padding-right: 61px;
      // background: #e5dfff;
      background: #f3f7ff;
      box-shadow: inset -3px 3px 4px 0px rgba(150, 150, 150, 0.08);

      // width: fit-content;
      // min-width: calc(50% - 61px);
      width: 50%;
      position: relative;

      .item-content {
        // width: fit-content;
        // float: right;
        max-width: 95%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .main-logo {
        margin-right: 70%;
      }

      .logo-candidate {
        margin-top: 84px;
        width: 100%;
        max-width: 90%;

        &>img {
          width: 100%;
          height: auto;
          max-width: 80vh;
        }
      }

      .desc-main {
        text-align: center;
        color: #212121;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 68px;
      }
    }

    .login-main-container {
      .login-container {
        // margin-left: 100px;
        // filter: drop-shadow(4px 4px 8px rgba(79, 79, 79, 0.1))
        //   drop-shadow(-4px -4px 8px rgba(79, 79, 79, 0.1));
        // max-width: 420px;
        // width: calc(50% - 40px);
        // padding: 20px;
        // padding-left: 80px;
        // justify-content: center;
        // flex-direction: column;
        // margin: 0 auto;
        // margin-left: 60px;
        // align-items: center;
        // padding-top: 0px;
        min-width: 450px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .title {
          color: #212121;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          // margin-top: 100px;
          margin-top: 20px;
          margin-bottom: 30px;
        }

        .input-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          // background: #fff;

          label {
            color: #212121;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            margin-bottom: 10px;
          }

          .email-input,
          input {
            padding: 13px 16px;
            border-radius: 8px;
            border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            background: #fff;
            outline: none;
            outline: none;
            font-family: "Poppins";
          }

          .email-input {
            // border: 1px solid red;
          }

          .email-input::placeholder {
            color: #dadada !important;
          }

          input::placeholder {
            color: #a7a7a7;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
          }

          &>div {
            &>div {
              border-radius: 8px;
            }

            input {
              border: none;
            }
          }

          input::placeholder {
            color: #a7a7a7;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
          }

          .errorBox {
            display: flex;
            gap: 6px;
            align-items: center;
            margin-top: 5px;
            color: #e5261c;
          }
        }

        .input-group .MuiOutlinedInput-root {
          &:hover .MuiOutlinedInput-notchedOutline {
            border-color: inherit;
          }

          &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: inherit;
            border-width: 1px;
          }
        }

        .input-group .MuiInputBase-root {
          &:focus {
            outline: none;
            // border-color: transparent;
            border-color: var(--Black-20, rgba(17, 17, 19, 0.2));
            box-shadow: none;
          }

          &:hover {
            border-color: var(--Black-20, rgba(17, 17, 19, 0.2));
          }
        }

        .input-group input {
          &:focus {
            outline: none;
          }
        }

        input::placeholder {
          color: #a7a7a7 !important;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .checkbox-group {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;

            input {
              width: 20px;
              height: 20px;
            }

            label {
              margin-left: 5px;
              color: #4f4f4f;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              /* 22.4px */
              font-family: "Poppins";
            }
          }

          .forgot-link {
            color: #0b52d4;
            font-family: "Poppins";
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
          }
        }

        .login-btn {
          width: 100%;
          padding: 13px 0;
          border-radius: 8px;
          // background: #4318ff;
          background: #0B52D4;

          color: #fff;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          /* 22.4px */
          border: none;
          outline: none;
          cursor: pointer;
          margin-top: 32px;

          &>span {
            width: 20px !important;
            height: 20px !important;
          }
        }

        .divider {
          margin: 0;
          padding: 0;
          text-align: center;
          color: #a7a7a7;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
          border-bottom: 1px solid #e0e0e0;
          height: 1px;
          position: relative;
          margin: 30px 0;

          span {
            // width: calc(100% - 20px);
            position: absolute;
            top: -10px;
            background: #f8f9fb;
            padding: 0 10px;
            margin-left: -20px;
          }
        }

        .register-link {
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
          text-align: center;
          margin-top: 24px;

          a {
            color: #0B52D4;
            font-size: "1rem";
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            margin-left: 5px;
          }
        }

        .sso-flow {
          width: 100%;
          margin-bottom: 24px;

          &>button {
            width: 100%;
            display: flex;
            padding: 13px 0;
            align-items: center;
            justify-content: center;
            gap: 20px;
            color: #4f4f4f;
            font-family: "Poppins";
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            outline: none;
            background: #fff;
            cursor: pointer;

            &>img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.mobile-ui {
  margin-top: 30px;
  text-align: center;

  .desc {
    margin: 0 auto;
    color: #4f4f44;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    margin-top: 24px;
    width: 75%;
  }

  .okay {
    border: none;
    outline: none;
    border-radius: 4px;
    background: #0B52D4;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    padding: 9px 22px;
    color: #fff;
    margin-top: 30px;
  }
}