@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(135, 170, 234, 0.6);
    }
    50% {
      box-shadow: 0 0 0 20px rgba(135, 170, 234, 0.6);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(135, 170, 234, 0.6);
    }
  }
  
  .dialog-pulse {
    animation: pulse 2s infinite ease-in-out; // Smooth transition in and out
    border-radius: 16px; // Ensure this matches your Dialog radius
  }
  