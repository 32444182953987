.slider-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.slider {
  display: flex;
  gap: 16px; /* Adds consistent gap between the cards */
}

.slider > * {
  flex: 0 0 20%;
  box-sizing: border-box;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .slider > * {
    flex: 0 0 100%;
  }
}
