.refer-job-main {
  width: 100%;
  max-width: 612px;
  position: relative;
  font-family: "Poppins";
  .main-img {
    width: 100%;
    & > img {
      width: 100%;
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
  }
  .title {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    .desc {
      color: #000;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
    }
  }
  .refer-details {
    padding: 0 20px;
    .each-line {
      display: flex;
      gap: 36px;
      margin-top: 30px;
      .input-container {
        width: 100%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        font-family: "Poppins";
        & > label {
          color: #212121;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          margin-bottom: 8px;
          font-family: "Poppins";
        }
        & > input,
        & > div > input {
          border-radius: 8px;
          border: 1px solid #9e9e9e;
          background: #fff;
          box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.04);
          outline: none;
          padding: 14px 16px;
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          font-family: "Poppins";
        }
        & > input::placeholder,
        & > div > input::placeholder {
          color: #a7a7a7;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
          font-family: "Poppins";
        }

        .dropDown-wrapper {
          .placeholder {
            border-radius: 8px;
            border: 1px solid #9e9e9e;
            background: #fff;
            box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.04);
            & > div:nth-child(2) {
              margin-top: 5px;
            }
          }
          .options-wrapper {
            box-shadow: 9px 6px 11px 17px rgb(86 83 83 / 6%);
          }
        }
        .phone {
          display: flex;
          gap: 14px;

          .dropDown-wrapper {
            width: 74px;
          }

          & > input {
            width: 100%;
            font-family: "Poppins";
          }
        }
      }
    }
    .submit {
      margin-top: 40px;
      & > input {
        border-radius: 8px;
        background: #062b6e;
        outline: none;
        border: none;
        padding: 13px 24px;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        width: fit-content;
        cursor: pointer;
        margin-bottom: 20px;
      }
    }
    & > form > div:nth-child(1) {
      //   border: 1px solid;
      margin-top: 0px;
    }
  }
}
