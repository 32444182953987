.subeader-container {
  margin-left: 5vw;
  font-family: "Poppins";
  margin-top: 42px;

  .titles-container {
    display: flex;
    margin-top: 40px;
    align-items: center;
    height: fit-content;

    .title-wrapper-completed,
    .title-wrapper-normal,
    .title-wrapper-first-not-found {
      display: flex;
      align-items: center;
      padding-left: 40px;
      gap: 5px;
      border-top: 2px solid #e0e0e0;
      padding-top: 10px;

      & > div:nth-child(2) {
        margin-bottom: 6px;
      }
    }

    & > div:nth-child(1) {
      padding-left: 0;
    }

    .title-wrapper-completed {
      color: #0bb762;
      border-top: 2px solid #0bb762;
    }
    .title-wrapper-first-not-found {
      color: #0b52d4;
      font-weight: 500;
    }
  }
}
